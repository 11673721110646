export class CurrentTimezoneModel {
  meetTimezone: string; // America/New_York
  meetShortTimezone: string; // EST
  meetTechieOffset: string; // +500
  localTimezone: string;
  localShortTimezone: string;
  localTechieOffset: string;
  activeTimezoneLabel: string; // local or meet
  activeTimezone!: string;
  activeShortTimezone!: string;
  activeTechieOffset!: string;

  constructor(
    meetTimezone: string,
    meetShortTimezone: string,
    meetTechieOffset: string,
    localTimezone: string,
    localShortTimezone: string,
    localTechieOffset: string,
    activeTimezoneLabel: string,
  ) {
    this.meetTimezone = meetTimezone;
    this.meetShortTimezone = meetShortTimezone;
    this.meetTechieOffset = meetTechieOffset;
    this.localTimezone = localTimezone;
    this.localShortTimezone = localShortTimezone;
    this.localTechieOffset = localTechieOffset;
    this.activeTimezoneLabel = activeTimezoneLabel;
    this.setActiveTimezone(activeTimezoneLabel);
  }

  setActiveTimezone(activeTimezoneLabel: string) {
    this.activeTimezoneLabel = activeTimezoneLabel;
    if (activeTimezoneLabel === 'local') {
      this.activeTimezone = this.localTimezone;
      this.activeShortTimezone = this.localShortTimezone;
      this.activeTechieOffset = this.localTechieOffset;
    }
    if (activeTimezoneLabel === 'meet') {
      this.activeTimezone = this.meetTimezone;
      this.activeShortTimezone = this.meetShortTimezone;
      this.activeTechieOffset = this.meetTechieOffset;
    }
  }
}
