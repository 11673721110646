export class EventFilterModel {
  firstLevelFilter: string | null = null;
  secondLevelFilter: string | null = null;
  firstLevelFilterName: string | null = null;

  constructor(firstLevelFilter: string | null, secondLevelFilter: string | null, firstLevelFilterName: string | null) {
    this.firstLevelFilter = firstLevelFilter;
    this.secondLevelFilter = secondLevelFilter;
    this.firstLevelFilterName = firstLevelFilterName;
  }

  isEmpty() {
    return this.firstLevelFilter === null && this.secondLevelFilter === null && this.firstLevelFilterName === null;
  }
}
