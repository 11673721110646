export class CurrentEventModel {
  meetId: number;
  category: string;
  id: number;

  constructor(meetId: number, category: string, id: number) {
    this.meetId = meetId;
    this.category = category;
    this.id = id;
  }
}
