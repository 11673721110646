import { Inject, Injectable } from '@angular/core';
import { CurrentMeetService } from './current-meet.service';
import { Meta, Title } from '@angular/platform-browser';
import { SiteConfigService } from './site-config.service';
import { combineLatest, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { MeetList } from '@athleticnet/athletic-live-api-models';
import { SiteConfigInterface } from '../models/config/site-config.interface';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    public currentMeetService: CurrentMeetService,
    public siteConfigService: SiteConfigService,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: any,
  ) {}

  async setTitle(title: string | null) {
    await firstValueFrom(
      combineLatest([this.currentMeetService.updateMeet, this.siteConfigService.updateSiteConfig]).pipe(
        map(([currentMeet, siteConfig]: [MeetList | null, SiteConfigInterface | null]) => {
          let titleStr = title;
          if (currentMeet) {
            if (!titleStr) {
              titleStr = currentMeet.longName + ' - ' + currentMeet.dateString;
            } else {
              titleStr +=
                (titleStr !== currentMeet.longName ? ' - ' + currentMeet.longName : '') +
                ' - ' +
                currentMeet.dateString;
            }
          }
          if (siteConfig) {
            if (!titleStr) {
              titleStr = siteConfig.ngConfig.siteName;
            } else {
              titleStr += ' | ' + siteConfig.ngConfig.siteName;
            }
          }

          if (!titleStr) {
            titleStr = 'AthleticLIVE';
          }

          this.title.setTitle(titleStr);
          this.setTag('og:title', titleStr);
          this.setTag('twitter:title', titleStr);
        }),
      ),
    );
  }

  /**
   * Set the default tags for the site, should only be called in the root component
   */
  async setSiteDefaultTags() {
    // RESET ROBOT TAGS
    this.setRobotTags(false, false);

    await lastValueFrom(
      this.siteConfigService.updateSiteConfig.pipe(
        map((siteConfig: SiteConfigInterface | null) => {
          if (siteConfig) {
            // Set Images
            this.setTag(
              'og:image',
              'https://live.athletic.net/assets/sites/' +
                siteConfig.ngConfig.machineName +
                (siteConfig.ngConfig.facebookImage ?? '/images/social/facebook-card-square.jpg'),
            );
            // this.setTag('og:image:type', 'image/jpeg'); // TODO: Change to dynamic
            this.setTag('og:image:alt', siteConfig.ngConfig.siteName + ' Facebook Share Logo');
            this.setTag(
              'twitter:image',
              'https://live.athletic.net/assets/sites/' +
                siteConfig.ngConfig.machineName +
                (siteConfig.ngConfig.twitterImage ?? '/images/social/twitter-card-square.jpg'),
            );

            // Twitter specific
            if (siteConfig.ngConfig.twitterLink) {
              this.setTag('twitter:site', siteConfig.ngConfig.twitterLink);
            }

            // FB specific
            this.setTag(
              'fb:app_id',
              !!siteConfig.ngConfig.facebookAppId ? siteConfig.ngConfig.facebookAppId : '133201834024935',
            );

            // Set Description
            this.setDescription(siteConfig.ngConfig.aboutOneLiner);
          }
        }),
      ),
    );

    this.setTag('og:type', 'website');
    this.setTag('og:site_name', 'AthleticLIVE');

    // Supported Locales
    this.setTag('og:locale', 'en_US');
    this.setTag('og:locale:alternate', 'fr_FR');
  }

  setDescription(description: string) {
    this.setTag('description', description);
    this.setTag('og:description', description);
    this.setTag('twitter:description', description);
  }

  setCanonicalUrl(url: string) {
    const head = this.document.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null;
    if (element === null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
    this.setTag('og:url', url);
  }

  setRobotTags(noIndex: boolean, noFollow: boolean) {
    let content = [];
    if (noIndex) {
      content.push('noindex');
    }
    if (noFollow) {
      content.push('nofollow');
    }

    if (content.length === 0) {
      this.removeTag('robots');
      this.removeTag('googlebot');
      return;
    }

    this.setTag('robots', content.join(', '));
    this.setTag('googlebot', content.join(', '));
  }

  protected removeTag(tagName: string) {
    if (tagName.includes('og:') || tagName.includes('fb:')) {
      this.meta.removeTag("property='" + tagName + "'");
    } else {
      this.meta.removeTag("name='" + tagName + "'");
    }
  }

  protected setTag(tagName: string, content: string) {
    let toInsert;
    let existingTag = false;
    if (tagName.includes('og:') || tagName.includes('fb:')) {
      existingTag = !!this.meta.getTag("property='" + tagName + "'");
      toInsert = { property: tagName, content };
    } else {
      existingTag = !!this.meta.getTag("name='" + tagName + "'");
      toInsert = { name: tagName, content };
    }

    if (existingTag) {
      this.meta.updateTag(toInsert);
    } else {
      this.meta.addTag(toInsert);
    }
  }
}
